@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

:root {
  --font-base: 'DM Sans', sans-serif;

  --primary-color: #19357E;
  --secondary-color: #313bac;
  --lightYellow-color: #FAF3AF;
  --black-color: #98ABDF;
  --lightGray-color: #e4e4e4;
  --gray-color: #C2D3FF;
  --brown-color: #46364a;
  --white-color: #32509E;

}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

.test{
  background-color: #fff;
}